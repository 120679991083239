import { queryCache, useMutation } from "react-query";
import { deleteSPUnit as deleteSPUnitAPI } from "../../../../api/spUnits";
import shallow from "zustand/shallow";
import useSPUnitsStore from "../../SPUnitsStore";

function useDeleteSPUnit() {
  const { isDeleteModalOpen, rowData, setDeleteModalOpen } = useSPUnitsStore(
    s => ({
      isDeleteModalOpen: s.isDeleteModalOpen,
      rowData: s.rowData,
      setDeleteModalOpen: s.setDeleteModalOpen
    }),
    shallow
  );

  const [deleteSPUnit, { status }] = useMutation(async (spUnitId: string) => {
    const res = await deleteSPUnitAPI(spUnitId);
    await queryCache.invalidateQueries("spUnits");
    return res.data;
  });

  const handleClose = () => setDeleteModalOpen(false, null);

  const handleDelete = async () => {
    await deleteSPUnit(rowData?.Id, {
      onSuccess: () => handleClose()
    });
  };

  return {
    isOpen: isDeleteModalOpen,
    close: handleClose,
    submit: handleDelete,
    submitStatus: status,
    spUnitName: rowData?.Name
  };
}

export default useDeleteSPUnit;
