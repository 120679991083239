import { useMemo } from "react";
import { queryCache, useMutation } from "react-query";
import shallow from "zustand/shallow";
import { isString } from "formik";

// API:
import { editSPUnit as editSPUnitAPI } from "../../../../api/spUnits";
import { EditSPUnitType } from "../../../../api/spUnits/types";

// LOGIC:
import useSPUnitsStore from "../../SPUnitsStore";
import { SPUnitFormValues } from "../SPUnitsForm";

function useEditSPUnit() {
  const { isEditModalOpen, rowData, setEditModalOpen } = useSPUnitsStore(
    s => ({
      isEditModalOpen: s.isEditModalOpen,
      rowData: s.rowData,
      setEditModalOpen: s.setEditModalOpen
    }),
    shallow
  );

  const handleClose = () => setEditModalOpen(false, null);

  const initialValues = useMemo<SPUnitFormValues>(
    () => ({
      name: rowData?.Name || "",
      lat: rowData?.Location?.Y || 0,
      lng: rowData?.Location?.X || 0,
      vendorAssignedId: rowData?.VendorAssignedId || ""
    }),
    [rowData]
  );

  const [editSPUnit, { status }] = useMutation(async (data: EditSPUnitType) => {
    const res = await editSPUnitAPI(data);
    await queryCache.invalidateQueries("spUnits");
    return res.data;
  });

  const handleSubmit = async (values: SPUnitFormValues) => {
    const mappedValues = {
      Id: rowData!.Id,
      Name: values.name,
      Location: {
        Y: values.lat === 0 || isString(values.lat) ? 0 : values.lat,
        X: values.lng === 0 || isString(values.lng) ? 0 : values.lng
      },
      VendorAssignedId: values.vendorAssignedId,
      LastUpdated: rowData!.LastUpdated
    };
    await editSPUnit(mappedValues, {
      onSuccess: () => handleClose()
    });
  };

  return {
    initialValues: initialValues,
    isOpen: isEditModalOpen,
    close: handleClose,
    submitStatus: status,
    submit: handleSubmit
  };
}

export default useEditSPUnit;
