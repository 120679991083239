import create, { State } from "zustand";
import { VesselType } from "../../api/vessels/types";

interface VesselsStore extends State {
  isNewModalOpen: boolean;
  setNewModalOpen: (value: boolean) => void;
  isEditModalOpen: boolean;
  setEditModalOpen: (value: boolean, rowData: VesselsStore["rowData"]) => void;
  isDeleteModalOpen: boolean;
  setDeleteModalOpen: (val: boolean, rowData: VesselsStore["rowData"]) => void;
  rowData: VesselType | null;
}

export const useVesselsStore = create<VesselsStore>(set => ({
  isNewModalOpen: false,
  setNewModalOpen: val => set(() => ({ isNewModalOpen: val })),
  isEditModalOpen: false,
  setEditModalOpen: (val, rowData) =>
    set(() => ({ isEditModalOpen: val, rowData: !!rowData ? rowData : null })),
  isDeleteModalOpen: false,
  setDeleteModalOpen: (val, rowData) =>
    set(() => ({
      isDeleteModalOpen: val,
      rowData: !!rowData ? rowData : null
    })),
  rowData: null
}));

// for debugging
// useVesselsStore.subscribe(console.log)

export default useVesselsStore;
