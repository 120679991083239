import { useMemo } from "react";
import { useQuery } from "react-query";
import { getSPUnits } from "../../../api/spUnits";

function useSupplyPitForm() {
  const { data: spUnits, isLoading } = useQuery("spUnits", async () => {
    const res = await getSPUnits();
    return res.data;
  });

  const spUnitsSelectOptions = useMemo(() => {
    if (spUnits) {
      return spUnits.map(spUnit => ({
        value: spUnit.Id,
        label: spUnit.Name
      }));
    }
    return [];
  }, [spUnits]);

  return { spUnitsSelectOptions, isSPUnitsLoading: isLoading };
}

export default useSupplyPitForm;
