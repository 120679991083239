import create, { State } from "zustand";

interface VesselsTableStore extends State {
  search: string;
  setSearch: (value: string) => void;
}

const useVesselsTableStore = create<VesselsTableStore>(set => ({
  search: "",
  setSearch: val => set({ search: val })
}));

// for debugging
// useVesselsTableStore.subscribe(console.log)

export default useVesselsTableStore;
