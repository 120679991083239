import React, { FC } from "react";
import { Card } from "../../_metronic/_partials/controls/index";

// COMPONENTS:
import SupplyPitsHeader from "./SupplyPitsTable/components/SupplyPitsHeader";
import SupplyPitsTableFilters from "./SupplyPitsTable/components/SupplyPitsTableFilters";
import SupplyPitsTable from "./SupplyPitsTable";
// import SupplyPitsPagination from "./SupplyPitsTable/components/SupplyPitsPagination";
import NewSupplyPitModal from "./components/NewSupplyPit/NewSupplyPitModal";
import EditSupplyPitModal from "./components/EditSupplyPit/EditSupplyPitModal";
import DeleteSupplyPitModal from "./components/DeleteSupplyPit/DeleteSupplyPitModal";

const SupplyPitsPage: FC = () => {
  return (
    <>
      {/* @ts-ignore */}
      <Card>
        <SupplyPitsHeader />
        <SupplyPitsTableFilters />
        <SupplyPitsTable />
        {/*<SupplyPitsPagination />*/}
      </Card>
      <NewSupplyPitModal />
      <EditSupplyPitModal />
      <DeleteSupplyPitModal />
    </>
  );
};

export default SupplyPitsPage;
