import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

// COMPONENTS:
import VesselForm from "../VesselForm";

// LOGIC:
import useEditVessel from "./useEditVessel";

const EditVesselModal: FC = () => {
  const {
    isOpen,
    close,
    submitStatus,
    submit,
    initialValues
  } = useEditVessel();

  return (
    <Modal
      show={isOpen}
      onHide={close}
      size="lg"
      backdrop="static"
      keyboard={false}
      autoFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="VESSELS.VESSEL_FORM.EDIT_FORM_TITLE" />
        </Modal.Title>
      </Modal.Header>
      <VesselForm
        handleSubmit={submit}
        handleClose={close}
        initialValues={initialValues}
        submitStatus={submitStatus}
      />
    </Modal>
  );
};

export default EditVesselModal;
