import React, { FC, useMemo } from "react";
import Select, { ValueType } from "react-select";
import { Form } from "react-bootstrap";

// TYPES:
import { FieldProps } from "formik/dist/Field";
import { Theme } from "react-select/src/types";
import { StylesConfig } from "react-select/src/styles";

export type SelectOption = { label: string; value: string };

interface Props extends FieldProps {
  options: SelectOption[];
}

// react-select styles
const targetHeight = 36;

const customTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#004f99"
  }
});

const FormikSelect: FC<Props> = ({ options, field, form, ...rest }) => {
  const isInvalid = form.touched[field.name] && !!form.errors[field.name];

  const styles = useMemo<StylesConfig>(
    () => ({
      control: base => ({
        ...base,
        minHeight: "initial",
        borderColor: isInvalid ? "#F64E60 !important" : "#E4E6EF"
      }),
      indicatorSeparator: base => ({
        ...base,
        backgroundColor: isInvalid ? "#F64E60 !important" : "#E4E6EF"
      }),
      valueContainer: base => ({
        ...base,
        height: `${targetHeight - 1 - 1}px`,
        padding: "0 8px"
      }),
      clearIndicator: base => ({
        ...base,
        padding: `${(targetHeight - 20 - 1 - 1) / 2}px`
      }),
      dropdownIndicator: base => ({
        ...base,
        padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        color: isInvalid ? "#F64E60 !important" : "#E4E6EF"
      })
    }),
    [isInvalid]
  );

  // value is of type SelectOption for rendering values correctly
  // e.g: value = { label: "Option 1", key: "opt1" }
  const value = options.find(option => option.value === field.value);

  // but onChange it sets formik field value to be just value of selected option
  // e.g: formik field value = "opt1"
  const handleChange = (option: ValueType<SelectOption>) => {
    return option
      ? form.setFieldValue(field.name, (option as SelectOption).value)
      : form.setFieldValue(field.name, "");
  };

  return (
    <>
      <Select
        options={options}
        name={field.name}
        value={value}
        onChange={handleChange}
        onBlur={field.onBlur}
        theme={customTheme}
        styles={styles}
        {...rest}
      />
      {isInvalid ? (
        <Form.Control.Feedback
          style={{ display: "block" }}
          type="invalid"
          data-testid="formik-select-error"
        >
          {form.errors[field.name]}
        </Form.Control.Feedback>
      ) : (
        <div className="input-feedback-text-placeholder" />
      )}
    </>
  );
};

export default FormikSelect;
