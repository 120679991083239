import { queryCache, useMutation } from "react-query";
import { deleteSupplyPit as deleteSupplyPitAPI } from "../../../../api/supplypits";
import shallow from "zustand/shallow";
import useSupplyPitsStore from "../../supplyPitsStore";

function useDeleteSupplyPit() {
  const { isDeleteModalOpen, rowData, setDeleteModalOpen } = useSupplyPitsStore(
    s => ({
      isDeleteModalOpen: s.isDeleteModalOpen,
      rowData: s.rowData,
      setDeleteModalOpen: s.setDeleteModalOpen
    }),
    shallow
  );

  const [deleteSupplyPit, { status }] = useMutation(
    async (supplyPitId: string) => {
      const res = await deleteSupplyPitAPI(supplyPitId);
      await queryCache.invalidateQueries("supplypits");
      return res.data;
    }
  );

  const handleClose = () => setDeleteModalOpen(false, null);

  const handleDelete = async () => {
    await deleteSupplyPit(rowData?.Id, {
      onSuccess: () => handleClose()
    });
  };

  return {
    isOpen: isDeleteModalOpen,
    close: handleClose,
    submit: handleDelete,
    submitStatus: status,
    supplyPitName: rowData?.Name
  };
}

export default useDeleteSupplyPit;
