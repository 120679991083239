/**
 * Fallback translations values to default translations language where parameter is equal null;
 * @param translations
 * @param defaultTranslations
 */
const fallbackTranslations = (translations, defaultTranslations) =>
  Object.keys(translations).reduce((prev, key) => {
    switch (true) {
      case translations[key] && typeof translations[key] === "object":
        prev[key] = fallbackTranslations(
          translations[key],
          defaultTranslations[key]
        );
        break;

      case !translations[key]:
        prev[key] = defaultTranslations[key];
        break;

      default:
        prev[key] = translations[key];
        break;
    }
    return prev;
  }, {});

export default fallbackTranslations;
