import mapboxgl, { GeoJSONSource, GeoJSONSourceRaw, Layer } from "mapbox-gl";
import { SupplyPitWebSocketType } from "../../../../api/supplypits/types";
import { SupplyPitsWithWebSocketData } from "../useSupplyPits";
import { MutableRefObject } from "react";

// icon-image maps state names with icon names
// lowercase string are names of icons
// that were uploaded to mapbox storage e.g: "state-off"
export const iconImageLayer: Layer = {
  id: "supply-pits",
  type: "symbol",
  source: "supply-pits",
  layout: {
    "icon-image": [
      "match",
      ["get", "state"],
      "NoData",
      "state-nodata",
      "On",
      "state-on",
      "On-alarm",
      "alarm-on",
      "On-warning",
      "warning-on",
      "Off",
      "state-off",
      "Off-alarm",
      "alarm-off",
      "Off-warning",
      "warning-off",
      "Starting",
      "state-starting",
      "Starting-alarm",
      "alarm-starting",
      "Starting-warning",
      "warning-starting",
      "Stopping",
      "state-stopping",
      "Stopping-alarm",
      "alarm-stopping",
      "Stopping-warning",
      "warning-stopping",
      ""
    ],
    "icon-allow-overlap": true,
    "icon-size": 1.5
  }
};

export function loadInitialSupplyPitsOntoMap(
  map: MutableRefObject<mapboxgl.Map | null>,
  socketsMap: Map<string, SupplyPitsWithWebSocketData>
) {
  map.current?.addSource("supply-pits", toGeoJson(socketsMap));
  map.current?.addLayer(iconImageLayer);
  fitBounds("supply-pits", map.current!);
}

export function updateSupplyPitsOnMap(
  currentSource: GeoJSONSource,
  socketsMap: Map<string, SupplyPitsWithWebSocketData>
) {
  currentSource.setData({
    type: "FeatureCollection",
    features: createFeatureCollection(socketsMap)
  });
}

// zoom map to fit all supplyPits in view
export function fitBounds(id: string, map: mapboxgl.Map) {
  const geoJSON = map.getSource(id) as any;
  let bounds = geoJSON!._data.features.reduce(function(
    bounds: any,
    feature: any
  ) {
    if (!Array.isArray(feature.geometry.coordinates[0])) {
      return bounds.extend(feature.geometry.coordinates);
    } else {
      return feature.geometry.coordinates.reduce(function(
        bounds: any,
        coord: any
      ) {
        return bounds.extend(coord);
      },
      bounds);
    }
  },
  new mapboxgl.LngLatBounds());

  const padding = document.fullscreenElement
    ? { top: 150, bottom: 100, left: 250, right: 250 }
    : { top: 10, bottom: 10, left: 10, right: 10 };
  map.fitBounds(bounds, {
    padding: padding
  });
}

// merge state and possible warnings and alarms together
// in order to match icon-image entries in mapbox Layer
function parseState({ HasAlarms, HasWarnings, State }: SupplyPitWebSocketType) {
  switch (true) {
    case HasAlarms:
      return `${State}-alarm`;

    case HasWarnings:
      return `${State}-warning`;

    default:
      return State;
  }
}

// transforms data into FeatureCollection structure
export function createFeatureCollection(
  data: Map<string, SupplyPitsWithWebSocketData>
) {
  const featureCollection: Array<GeoJSON.Feature> = [];
  data.forEach(supplyPit => {
    const feature: GeoJSON.Feature = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates:
          [supplyPit.supplyPit.Location.X, supplyPit.supplyPit.Location.Y] || []
      },
      properties: {
        id: supplyPit.supplyPit.Id || null,
        state: parseState(JSON.parse(supplyPit.socketData)),
        supplyPit: supplyPit.supplyPit,
        socketData: supplyPit.socketData
      }
    };
    featureCollection.push(feature);
  });
  return featureCollection;
}

// transforms data into GeoJSON structure
export function toGeoJson(
  data: Map<string, SupplyPitsWithWebSocketData>
): GeoJSONSourceRaw {
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: createFeatureCollection(data) || []
    }
  };
}
