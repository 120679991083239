import request, { APICall } from "../request";
import { SPUnitType, AddSPUnitType, EditSPUnitType } from "./types";

export const getSPUnits = (): APICall<SPUnitType[]> =>
  request({
    url: "/shorepowerunits",
    method: "GET"
  });

export const addSPUnit = (data: AddSPUnitType): APICall<AddSPUnitType> =>
  request({
    data,
    url: "/shorepowerunits",
    method: "POST"
  });

export const editSPUnit = (
  data: EditSPUnitType
): APICall<EditSPUnitType> =>
  request({
    data,
    url: "/shorepowerunits",
    method: "PATCH"
  });

export const deleteSPUnit = (customerId: string): APICall<null> =>
  request({
    url: `/shorepowerunits/${customerId}`,
    method: "DELETE"
  });
