import React, { FC } from "react";
// import { Row, Col } from "react-bootstrap";

// COMPONENTS:
import Map from "./Map";
// import RecentActivities from "./RecentActivities";
// import ActivePortCalls from "./ActivePortCalls";

const DashboardPage: FC = () => (
  <>
    {/*<Row>*/}
    {/*<Col xs={12} lg={8} className="spcs-map-container mb-7 mb-lg-0">*/}
    {/*  <Map />*/}
    {/*</Col>*/}
    {/*<Col xs={12} lg={4} className="recent-activities-container">*/}
    {/*  <RecentActivities />*/}
    {/*</Col>*/}
    {/*</Row>*/}
    {/*<Row className=" mt-7">*/}
    {/*  <Col>*/}
    {/*    <ActivePortCalls />*/}
    {/*  </Col>*/}
    {/*</Row>*/}
    <Map />
  </>
);

export default DashboardPage;
