import { useQuery } from "react-query";
import { getVessels } from "../../../api/vessels";
import shallow from "zustand/shallow";
import useVesselsTableStore from "./useVesselsTableStore";
import useLocalSearch from "../../../utils/hooks/useLocalSearch";

function useVessels() {
  const { search } = useVesselsTableStore(s => ({ search: s.search }), shallow);
  const { data: vessels } = useQuery("vessels", async () => {
    const res = await getVessels();
    return res.data;
  });

  const { data } = useLocalSearch(search, ["Name", "Imo"], vessels);

  return { data };
}

export default useVessels;
