import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export const SortCaret = (order: "asc" | "desc" | undefined) => {
  switch (order) {
    case "asc":
      return (
        <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Up-2.svg")} />
        </span>
      );

    case "desc":
      return (
        <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Down-2.svg")} />
        </span>
      );

    default:
      return (
        <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Sort1.svg")} />
        </span>
      );
  }
};

export const headerSortingClasses = (
  column: any,
  sortOrder: "asc" | "desc",
  isLastSorting: boolean,
  colIndex: number
) => {
  return sortOrder === "asc" || sortOrder === "desc" ? "sortable-active" : "";
};
