import React, { FC } from "react";
import { Formik, Form } from "formik";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { QueryStatus } from "react-query";

// COMPONENTS:
import FormGroupField from "../../../components/forms/FormGroupField";
import FormikInput from "../../../components/forms/FormikInput";
import ButtonLoading from "../../../components/forms/ButtonLoading";

export type SPUnitFormValues = {
  name: string;
  lat: string | number;
  lng: string | number;
  vendorAssignedId: string;
};

interface Props {
  handleSubmit: (values: SPUnitFormValues) => void;
  handleClose: () => void;
  initialValues?: SPUnitFormValues;
  submitStatus?: QueryStatus;
}

const SPUnitsForm: FC<Props> = ({
  submitStatus,
  handleClose,
  handleSubmit,
  initialValues = {
    name: "",
    lat: "",
    lng: "",
    vendorAssignedId: ""
  }
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <>
            <Form noValidate>
              <Modal.Body>
                <Row>
                  <Col xs={6} lg={4}>
                    <FormGroupField
                      name="name"
                      component={FormikInput}
                      label={<FormattedMessage id="SPUNITS.SPUNIT_FORM.NAME" />}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} lg={4}>
                    <FormGroupField
                      name="lat"
                      type="number"
                      component={FormikInput}
                      label={
                        <FormattedMessage id="SPUNITS.SPUNIT_FORM.LATITUDE" />
                      }
                    />
                  </Col>
                  <Col xs={6} lg={4}>
                    <FormGroupField
                      name="lng"
                      type="number"
                      component={FormikInput}
                      label={
                        <FormattedMessage id="SPUNITS.SPUNIT_FORM.LONGITUDE" />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} lg={4}>
                    <FormGroupField
                      name="vendorAssignedId"
                      component={FormikInput}
                      label={
                        <FormattedMessage id="SPUNITS.SPUNIT_FORM.VENDOR_ASSIGNED_ID" />
                      }
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  <FormattedMessage id="GLOBAL.CLOSE" />
                </Button>
                <ButtonLoading
                  status={submitStatus}
                  variant="primary"
                  type="submit"
                >
                  <FormattedMessage id="GLOBAL.SAVE" />
                </ButtonLoading>
              </Modal.Footer>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default SPUnitsForm;
