import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { ColumnFormatter } from "react-bootstrap-table-next";

// TYPES:
import { VesselType } from "../../../../../api/vessels/types";

type ExtraFormatterProps = {
  openEditModal: (rowData: VesselType) => void;
  openDeleteModal: (rowData: VesselType) => void;
};

const ActionsFormatter: ColumnFormatter<
  VesselType,
  ExtraFormatterProps,
  undefined
> = (cell, row, rowIndex, { openEditModal, openDeleteModal }) => {
  const handleEditClick = () => {
    openEditModal(row);
  };

  const handleDeleteClick = () => {
    openDeleteModal(row);
  };

  return (
    <>
      <button
        className="btn btn-icon btn-light btn-hover-primary btn-sm ml-3"
        onClick={handleEditClick}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          />
        </span>
      </button>
      <button
        className="btn btn-icon btn-light btn-hover-primary btn-sm ml-3"
        onClick={handleDeleteClick}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </button>
    </>
  );
};

export default ActionsFormatter;
