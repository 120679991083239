import { useQuery } from "react-query";
import { getSupplyPits } from "../../../api/supplypits";

// LOGIC:
import useLocalSearch from "../../../utils/hooks/useLocalSearch";
import useSupplyPitsTableStore from "./useSupplyPitsTableStore";
import shallow from "zustand/shallow";

function useSupplyPits() {
  const { search } = useSupplyPitsTableStore(
    s => ({ search: s.search }),
    shallow
  );

  const { data: supplypits } = useQuery("supplypits", async () => {
    const res = await getSupplyPits();
    return res.data;
  });

  const { data } = useLocalSearch(
    search,
    ["Name", "LocationName", "Location.Y", "Location.X" ,"HighVoltage"],
    supplypits
  );

  return { data };
}

export default useSupplyPits;
