import React from "react";
import { FormattedMessage } from "react-intl";
import { useAuth0 } from "@auth0/auth0-react";

function Login() {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect();
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-15">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.LOGIN.SUBTITLE" />
        </p>
      </div>
      <div className="text-center">
        <button
          id="kt_login_signin_submit"
          className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          onClick={handleLogin}
        >
          <FormattedMessage id="AUTH.LOGIN.BUTTON" />
        </button>
      </div>
    </div>
  );
}

export default Login;
