import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

// COMPONENTS:
import SPUnitsForm from "../SPUnitsForm";
import useEditSPUnit from "./useEditSPUnit";

const EditSPUnitModal: FC = () => {
  const {
    isOpen,
    close,
    initialValues,
    submit,
    submitStatus
  } = useEditSPUnit();

  return (
    <Modal
      show={isOpen}
      onHide={close}
      size="lg"
      backdrop="static"
      keyboard={false}
      autoFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="SPUNITS.SPUNIT_FORM.EDIT_FORM_TITLE" />
        </Modal.Title>
      </Modal.Header>
      <SPUnitsForm
        submitStatus={submitStatus}
        handleSubmit={submit}
        initialValues={initialValues}
        handleClose={close}
      />
    </Modal>
  );
};

export default EditSPUnitModal;
