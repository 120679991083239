import React, {FC} from 'react';
import { SnackbarProvider as SnackbarActualProvider } from 'notistack';

const SnackbarProvider: FC = ({ children}) => {
  return (
    <SnackbarActualProvider>
      {children}
    </SnackbarActualProvider>
  );
};

export default SnackbarProvider;
