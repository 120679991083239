import React, { FC, useEffect, useLayoutEffect } from "react";
import useMapbox from "./useMapbox";

const Map: FC = () => {
  const { initializeMap } = useMapbox("mapBoxContainer");

  // hax to remove container class from wrapper node
  // and display map on full width
  useLayoutEffect(() => {
    const parentNode = document.getElementById("mapBoxContainer")
      ?.parentElement;

    parentNode?.setAttribute("class", "flex-grow-1");
    parentNode?.setAttribute("style", "padding-right: 25px; padding-left: 25px;");
  }, []);

  useEffect(() => {
    initializeMap();
  }, [initializeMap]);

  return <div id="mapBoxContainer" className="map-container" />;
};

export default Map;
