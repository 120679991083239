import React, {FC} from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

// COMPONENTS:
import VesselForm from "../VesselForm";

// UI LOGIC:
import useNewVessel from "./useNewVessel";

const NewVesselModal: FC = () => {
  const { isOpen, submit, close, submitStatus } = useNewVessel();

  return (
    <Modal
      show={isOpen}
      onHide={close}
      size="lg"
      backdrop="static"
      keyboard={false}
      autoFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="VESSELS.VESSEL_FORM.NEW_FORM_TITLE" />
        </Modal.Title>
      </Modal.Header>
      <VesselForm
        handleClose={close}
        handleSubmit={submit}
        submitStatus={submitStatus}
      />
    </Modal>
  );
};

export default NewVesselModal;
