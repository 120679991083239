import React, { FC, useEffect, useState } from "react";
import { Redirect, RouteProps, Route, match } from "react-router-dom";
import shallow from "zustand/shallow";
import { Layout, LayoutSplashScreen } from "../../_metronic/layout";

// STORE:
import useBreadcrumbStore, { Breadcrumb } from "../stores/BreadcrumbStore";

// UTILS:
import { useAuth0 } from "@auth0/auth0-react";
import { axiosClient } from "../../api/request";

interface Props extends RouteProps {
  computedMatch?: match;
  breadcrumbs: Breadcrumb[];
}

const PrivateRoute: FC<Props> = ({ breadcrumbs, ...rest }) => {
  const [hasToken, setToken] = useState(false);
  const { setBreadcrumbs } = useBreadcrumbStore(
    s => ({ setBreadcrumbs: s.setBreadcrumbs }),
    shallow
  );

  /**
   * Auth0 flow:
   * 1. signing in through /login and Auth0
   * 2. on success redirect onto /dashboard - this component gets called
   *    - we should display <LayoutSplashScreen />
   * 3. useAuth0 hook loads and checks if user is authenticated: isLoading = true, isAuthenticated = false
   *    - we should still display <LayoutSplashScreen />
   * 4. when above finishes: isLoading = true and if user is authenticated then isAuthenticated = true
   *    - we should still display <LayoutSplashScreen />
   *    - we need to pass Authorization: Bearer {token} in every request from now on so
   *    - we need to fetch this token, because there is none available straight from useAuth0
   * 5. run useEffect with getIdTokenClaims() which will return token
   *    - we should still display <LayoutSplashScreen />
   * 6. set Authorization header with tokens value and set hasToken state to true
   *    - we can display children components
   */

  const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getIdTokenClaims().then(res => {
        axiosClient.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.__raw}`;
        setToken(true);
      });
    }
  }, [isLoading, isAuthenticated, getIdTokenClaims]);

  // is loading or
  // is not loading and its authenticated, but still fetching token
  if (isLoading || (!isLoading && isAuthenticated && !hasToken)) {
    return <LayoutSplashScreen />;
  }

  // is not loading, is authenticated and have token
  if (!isLoading && isAuthenticated && hasToken) {
    if (breadcrumbs) {
      setBreadcrumbs(breadcrumbs, rest.computedMatch!.params);
    }

    return (
      <Layout>
        <Route {...rest} />
      </Layout>
    );
  }

  // if it's not loading and it's not authenticated or don't have token
  return <Redirect to="/login" />;
};

export default PrivateRoute;
