import React, { FC } from "react";
import { Card } from "../../_metronic/_partials/controls";

// COMPONENTS:
import SPUnitsHeader from "./SPUnitsTable/components/SPUnitsHeader";
import SPUnitsTableFilters from "./SPUnitsTable/components/SPUnitsTableFilters";
import SPUnitsTable from "./SPUnitsTable";
// import SPUnitsPagination from "./SPUnitsTable/components/SPUnitsPagination";
import NewSPUnitModal from "./components/NewSPUnit/NewSPUnitModal";
import EditSPUnitModal from "./components/EditSPUnit/EditSPUnitModal";
import DeleteSPUnitModal from "./components/DeleteSPUnit/DeleteSPUnitModal";

const SPUnitsPage: FC = () => {
  return (
    <>
      {/* @ts-ignore */}
      <Card>
        <SPUnitsHeader />
        <SPUnitsTableFilters />
        <SPUnitsTable />
        {/*<SPUnitsPagination />*/}
      </Card>
      <NewSPUnitModal />
      <EditSPUnitModal />
      <DeleteSPUnitModal />
    </>
  );
};

export default SPUnitsPage;
