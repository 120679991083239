import React, { FC } from "react";
import { Formik, Form as FormikForm, Field } from "formik";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

// COMPONENTS:
import FormGroupField from "../../../components/forms/FormGroupField";
import FormikInput from "../../../components/forms/FormikInput";
import FormikCheckbox from "../../../components/forms/FormikCheckbox";
import ButtonLoading from "../../../components/forms/ButtonLoading";

// HELPERS:
import { QueryStatus } from "react-query";
import useSupplyPitForm from "./useSupplyPitForm";
import FormikSelect from "../../../components/forms/FormikSelect";
import * as yup from "yup";

export type SupplyPitFormValues = {
  shorePowerUnitId: string;
  name: string;
  locationName: string;
  lat: string | number;
  lng: string | number;
  highVoltage: boolean;
  vendorAssignedId: string;
};

interface Props {
  handleSubmit: (values: SupplyPitFormValues) => void;
  handleClose: () => void;
  initialValues?: SupplyPitFormValues;
  submitStatus?: QueryStatus;
}

const schema = yup.object({
  shorePowerUnitId: yup.string().required()
});

const SupplyPitForm: FC<Props> = ({
  submitStatus,
  handleClose,
  handleSubmit,
  initialValues = {
    shorePowerUnitId: "",
    name: "",
    locationName: "",
    lat: "",
    lng: "",
    highVoltage: false,
    vendorAssignedId: ""
  }
}) => {
  const { spUnitsSelectOptions, isSPUnitsLoading } = useSupplyPitForm();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <>
            <FormikForm noValidate>
              <Modal.Body>
                <Row>
                  <Col xs={6} lg={4}>
                    <FormGroupField
                      name="shorePowerUnitId"
                      component={FormikSelect}
                      options={spUnitsSelectOptions}
                      isSearchable
                      isClearable
                      isLoading={isSPUnitsLoading}
                      label={
                        <FormattedMessage id="SUPPLYPITS.SUPPLYPIT_FORM.SHORE_POWER_UNIT_ID" />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} lg={4}>
                    <FormGroupField
                      name="name"
                      component={FormikInput}
                      label={
                        <FormattedMessage id="SUPPLYPITS.SUPPLYPIT_FORM.NAME" />
                      }
                    />
                  </Col>
                  <Col xs={6} lg={4}>
                    <FormGroupField
                      name="locationName"
                      component={FormikInput}
                      label={
                        <FormattedMessage id="SUPPLYPITS.SUPPLYPIT_FORM.LOCATION_NAME" />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} lg={4}>
                    <FormGroupField
                      name="lat"
                      type="number"
                      component={FormikInput}
                      label={
                        <FormattedMessage id="SUPPLYPITS.SUPPLYPIT_FORM.LATITUDE" />
                      }
                    />
                  </Col>
                  <Col xs={6} lg={4}>
                    <FormGroupField
                      name="lng"
                      type="number"
                      component={FormikInput}
                      label={
                        <FormattedMessage id="SUPPLYPITS.SUPPLYPIT_FORM.LONGITUDE" />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} lg={4}>
                    <FormGroupField
                      name="vendorAssignedId"
                      component={FormikInput}
                      label={
                        <FormattedMessage id="SUPPLYPITS.SUPPLYPIT_FORM.VENDOR_ASSIGNED_ID" />
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} lg={4}>
                    <Field
                      name="highVoltage"
                      component={FormikCheckbox}
                      label={
                        <FormattedMessage id="SUPPLYPITS.SUPPLYPIT_FORM.HIGH_VOLTAGE" />
                      }
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  <FormattedMessage id="GLOBAL.CLOSE" />
                </Button>
                <ButtonLoading
                  status={submitStatus}
                  variant="primary"
                  type="submit"
                >
                  <FormattedMessage id="GLOBAL.SAVE" />
                </ButtonLoading>
              </Modal.Footer>
            </FormikForm>
          </>
        );
      }}
    </Formik>
  );
};

export default SupplyPitForm;
