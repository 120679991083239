import Cookies from "js-cookie";

export const setToken = (token: string) => {
  Cookies.set("accessToken", token, { expires: 30 });
};

export const isAuthenticated = () => {
  const token = Cookies.get("accessToken");
  return !!token;
};

export const deleteToken = () => {
  Cookies.remove("accessToken");
};

export const getToken = () => Cookies.get("accessToken");
