import { queryCache, useMutation } from "react-query";
import { deleteVessel as deleteVesselAPI } from "../../../../api/vessels";
import useVesselsStore from "../../vesselsStore";
import shallow from "zustand/shallow";

function useDeleteVessel() {
  const { isDeleteModalOpen, rowData, setDeleteModalOpen } = useVesselsStore(
    s => ({
      isDeleteModalOpen: s.isDeleteModalOpen,
      rowData: s.rowData,
      setDeleteModalOpen: s.setDeleteModalOpen
    }),
    shallow
  );

  const [deleteVessel, { status }] = useMutation(async (customerId: string) => {
    const res = await deleteVesselAPI(customerId);
    await queryCache.invalidateQueries("vessels");
    return res.data;
  });

  const handleClose = () => setDeleteModalOpen(false, null);

  const handleDelete = async () => {
    await deleteVessel(rowData!.Id, {
      onSuccess: () => handleClose()
    });
  };

  return {
    isOpen: isDeleteModalOpen,
    close: handleClose,
    submit: handleDelete,
    submitStatus: status,
    vesselName: rowData?.Name
  };
}

export default useDeleteVessel;
