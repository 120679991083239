import React, { FC } from "react";
import { Form, Formik } from "formik";
import useSPUnitsTableStore from "../useSPUnitsTableStore";
import shallow from "zustand/shallow";
import { useIntl } from "react-intl";

const SPUnitsTableFilters: FC = () => {
  const { setSearch } = useSPUnitsTableStore(
    s => ({ setSearch: s.setSearch }),
    shallow
  );

  const intl = useIntl();

  type FiltersFormValues = {
    search: string;
  };

  const initialValues: FiltersFormValues = {
    search: ""
  };

  const handleSubmit = (values: FiltersFormValues) => {
    setSearch(values.search);
  };

  return (
    <div className="p-5 border-bottom">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ setFieldValue, handleSubmit }) => (
          <Form className="row justify-content-end">
            <div className="col-3">
              <input
                type="text"
                className="form-control"
                name="search"
                placeholder={intl.formatMessage({ id: "GLOBAL.SEARCH" })}
                onChange={e => {
                  setFieldValue("search", e.target.value);
                  handleSubmit();
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SPUnitsTableFilters;
