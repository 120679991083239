import { queryCache, useMutation } from "react-query";
import shallow from "zustand/shallow";

// API:
import { addSupplyPit as addSupplyPitAPI } from "../../../../api/supplypits";

// LOGIC:
import { SupplyPitFormValues } from "../SupplyPitForm";
import { NewSupplyPitType } from "../../../../api/supplypits/types";
import useSupplyPitsStore from "../../supplyPitsStore";
import { isString } from "formik";

function useNewSupplyPit() {
  const { isNewModalOpen, setNewModalOpen } = useSupplyPitsStore(
    s => ({
      isNewModalOpen: s.isNewModalOpen,
      setNewModalOpen: s.setNewModalOpen
    }),
    shallow
  );

  const handleClose = () => setNewModalOpen(false);

  const [addSupplyPit, { status }] = useMutation(
    async (data: NewSupplyPitType) => {
      const res = await addSupplyPitAPI(data);
      await queryCache.invalidateQueries("supplypits");
      return res.data;
    }
  );

  const handleSubmit = async (values: SupplyPitFormValues) => {
    const mappedValues = {
      ShorePowerUnitId: values.shorePowerUnitId,
      Name: values.name,
      LocationName: values.locationName,
      Location: {
        Y: values.lat === 0 || isString(values.lat) ? 0 : values.lat,
        X: values.lng === 0 || isString(values.lng) ? 0 : values.lng
      },
      HighVoltage: values.highVoltage,
      VendorAssignedId: values.vendorAssignedId
    };
    await addSupplyPit(mappedValues, {
      onSuccess: () => handleClose()
    });
  };

  return {
    isOpen: isNewModalOpen,
    close: handleClose,
    submitStatus: status,
    submit: handleSubmit
  };
}

export default useNewSupplyPit;
