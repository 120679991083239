import create, { State } from "zustand";
import { SPUnitType } from "../../api/spUnits/types";

interface SPUnitsStore extends State {
  isNewModalOpen: boolean;
  setNewModalOpen: (val: boolean) => void;
  isEditModalOpen: boolean;
  setEditModalOpen: (val: boolean, rowData: SPUnitsStore["rowData"]) => void;
  isDeleteModalOpen: boolean;
  setDeleteModalOpen: (
    val: boolean,
    rowData: SPUnitsStore["rowData"]
  ) => void;
  rowData: SPUnitType | null;
}

export const useSPUnitsStore = create<SPUnitsStore>(set => ({
  isNewModalOpen: false,
  setNewModalOpen: val => set(() => ({ isNewModalOpen: val })),
  isEditModalOpen: false,
  setEditModalOpen: (val, rowData) =>
    set(() => ({ isEditModalOpen: val, rowData: !!rowData ? rowData : null })),
  isDeleteModalOpen: false,
  setDeleteModalOpen: (val, rowData) =>
    set(() => ({
      isDeleteModalOpen: val,
      rowData: !!rowData ? rowData : null
    })),
  rowData: null
}));

// for debugging
// useSPUnitsStore.subscribe(console.log)

export default useSPUnitsStore;
