import React from "react";
import { toAbsoluteUrl } from "../../../_helpers";
import { FormattedMessage } from "react-intl";

const CustomFooter = () => {
  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column`}
      id="kt_footer"
    >
      <div
        className={`container d-flex flex-column flex-md-row align-items-center`}
      >
        <img
          className="logo-default max-h-20px mr-3"
          alt="Logo"
          src={toAbsoluteUrl("/media/logos/plug_logo.png")}
        />
        <div className="text-muted font-weight-bold ml-2 mr-2">
          {new Date().getFullYear()} &copy;
        </div>
        <div className="text-dark-75">
          <FormattedMessage id="BRAND.NAME" />
        </div>
      </div>
    </div>
  );
};

export default CustomFooter;
