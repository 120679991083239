import { useDebounce } from "../useDebounce";
import { getNestedValue } from "../../getNestedValue";
import { isObject } from "../../isObject";

type FilterObjectType = {
  type: string;
  fieldName: string;
  parser?: (value: any) => string;
};

type FilterType = string | FilterObjectType;

/**
 * @param searchValue - search string
 * @param arr - data to look through
 * @param filters
 * this is array of object properties that search function will check for.
 * string is default expected value of object property,
 * if value is a date, it should be passed as as object for better search accuracy e.g:
 * - filters: ['name', { fieldName: 'dateOfBirth', type: 'date', parser: function }]
 * - filters.parser is optional
 * - e.g. default date parser looks like: (value) => new Date(value).toLocaleString()
 * - pass custom parser that matches your date formatter
 * @param options optional properties that tweaks behaviour of search
 * - interval: interval passed to useDebounce hook function
 */
const useLocalSearch = <T>(
  searchValue: string,
  filters: FilterType[],
  arr: T[] | undefined,
  options: { interval: number } = { interval: 200 }
) => {
  const debouncedString = useDebounce(searchValue, options.interval);
  return { data: search<T>(debouncedString, filters, arr), originalData: arr };
};

export default useLocalSearch;

// search function
function search<T>(
  searchValue: string,
  filters: FilterType[],
  arr: T[] | undefined
): T[] {
  // if arr is undefined, return empty array
  if (!arr) {
    return [];
  }

  // if searchValue is not empty string and there are filters to look through
  if (searchValue !== "" && filters.length > 0) {
    return arr.filter(item =>
      filters.some(filter => {
        // if current filter is an object
        if (isObject(filter)) {
          switch (filter.type) {
            case "date":
              return searchDateType(searchValue, item, filter);

            default:
              return searchDefaultType(searchValue, item, filter);
          }
        }
        return searchDefault(searchValue, item, filter);
      })
    );
  }

  // if searchString is empty or filters are empty, return original data
  return arr;
}

// Utils for search function
const defaultDateParser = (value: any): string =>
  new Date(value).toLocaleString();

const searchDateType = <T>(
  searchValue: string,
  item: T,
  currentFilter: FilterObjectType
) => {
  if (currentFilter.parser) {
    return currentFilter
      .parser(getNestedValue(currentFilter.fieldName, item))
      ?.includes(searchValue);
  }
  return defaultDateParser(
    getNestedValue(currentFilter.fieldName, item)
  )?.includes(searchValue);
};

const searchDefaultType = <T>(
  searchValue: string,
  item: T,
  currentFilter: FilterObjectType
) => {
  return getNestedValue(currentFilter.fieldName, item)
    ?.toString()
    .toLowerCase()
    .includes(searchValue.toLowerCase());
};

const searchDefault = <T>(
  searchValue: string,
  item: T,
  currentFilter: string
) => {
  return getNestedValue(currentFilter, item)
    ?.toString()
    .toLowerCase()
    .includes(searchValue.toLowerCase());
};
