import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

// COMPONENTS:
import SPUnitsForm from "../SPUnitsForm";

// LOGIC:
import useNewSPUnit from "./useNewSPUnit";

const NewSPUnitModal: FC = () => {
  const { isOpen, close, submit, submitStatus } = useNewSPUnit();

  return (
    <Modal
      show={isOpen}
      onHide={close}
      size="lg"
      backdrop="static"
      keyboard={false}
      autoFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="SPUNITS.SPUNIT_FORM.NEW_FORM_TITLE" />
        </Modal.Title>
      </Modal.Header>
      <SPUnitsForm
        submitStatus={submitStatus}
        handleSubmit={submit}
        handleClose={close}
      />
    </Modal>
  );
};

export default NewSPUnitModal;
