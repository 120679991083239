import create, { State } from "zustand";

interface SPUnitsTableStore extends State {
  search: string;
  setSearch: (value: string) => void;
}

const useSPUnitsTableStore = create<SPUnitsTableStore>(set => ({
  search: "",
  setSearch: val => set({ search: val })
}));

// for debugging
// useSPUnitsTableStore.subscribe(console.log)

export default useSPUnitsTableStore;
