import { queryCache, useMutation } from "react-query";
import shallow from "zustand/shallow";
import { isString } from "formik";

// API:
import { addSPUnit as addSPUnitAPI } from "../../../../api/spUnits";
import { AddSPUnitType } from "../../../../api/spUnits/types";

// LOGIC:
import useSPUnitsStore from "../../SPUnitsStore";
import { SPUnitFormValues } from "../SPUnitsForm";

function useNewSPUnit() {
  const { isNewModalOpen, setNewModalOpen } = useSPUnitsStore(
    s => ({
      isNewModalOpen: s.isNewModalOpen,
      setNewModalOpen: s.setNewModalOpen
    }),
    shallow
  );

  const handleClose = () => setNewModalOpen(false);

  const [addSPUnit, { status }] = useMutation(async (data: AddSPUnitType) => {
    const res = await addSPUnitAPI(data);
    await queryCache.invalidateQueries("spUnits");
    return res.data;
  });

  const handleSubmit = async (values: SPUnitFormValues) => {
    const mappedValues = {
      Name: values.name,
      Location: {
        Y: values.lat === 0 || isString(values.lat) ? 0 : values.lat,
        X: values.lng === 0 || isString(values.lng) ? 0 : values.lng
      },
      VendorAssignedId: values.vendorAssignedId
    };
    await addSPUnit(mappedValues, {
      onSuccess: () => handleClose()
    });
  };

  return {
    isOpen: isNewModalOpen,
    close: handleClose,
    submitStatus: status,
    submit: handleSubmit
  };
}

export default useNewSPUnit;
