import create, { State } from "zustand";
import { SupplyPitType } from "../../api/supplypits/types";

interface SupplyPitsStore extends State {
  isNewModalOpen: boolean;
  setNewModalOpen: (val: boolean) => void;
  isEditModalOpen: boolean;
  setEditModalOpen: (val: boolean, rowData: SupplyPitsStore["rowData"]) => void;
  isDeleteModalOpen: boolean;
  setDeleteModalOpen: (
    val: boolean,
    rowData: SupplyPitsStore["rowData"]
  ) => void;
  rowData: SupplyPitType | null;
}

export const useSupplyPitsStore = create<SupplyPitsStore>(set => ({
  isNewModalOpen: false,
  setNewModalOpen: val => set(() => ({ isNewModalOpen: val })),
  isEditModalOpen: false,
  setEditModalOpen: (val, rowData) =>
    set(() => ({ isEditModalOpen: val, rowData: !!rowData ? rowData : null })),
  isDeleteModalOpen: false,
  setDeleteModalOpen: (val, rowData) =>
    set(() => ({
      isDeleteModalOpen: val,
      rowData: !!rowData ? rowData : null
    })),
  rowData: null
}));

// for debugging
// useSupplyPitsStore.subscribe(console.log)

export default useSupplyPitsStore;
