import { useMemo } from "react";
import { queryCache, useMutation } from "react-query";

// API:
import { editVessel as editVesselAPI } from "../../../../api/vessels";

// LOGIC:
import useVesselsStore from "../../vesselsStore";
import shallow from "zustand/shallow";
import {EditVesselType} from "../../../../api/vessels/types";
import {VesselFormValues} from "../VesselForm";

function useEditVessel() {
  const { isEditModalOpen, rowData, setEditModalOpen } = useVesselsStore(
    s => ({
      isEditModalOpen: s.isEditModalOpen,
      rowData: s.rowData,
      setEditModalOpen: s.setEditModalOpen
    }),
    shallow
  );

  const handleClose = () => setEditModalOpen(false, null);

  const initialValues = useMemo(
    () => ({
      vesselName: rowData?.Name || "",
      vesselImo: rowData?.Imo || ""
    }),
    [rowData]
  );

  const [editVessel, { status }] = useMutation(async (data: EditVesselType ) => {
    const res = await editVesselAPI(data);
    await queryCache.invalidateQueries("vessels");
    return res.data;
  });

  const handleSubmit = async (values: VesselFormValues) => {
    const mappedValues = {
      Id: rowData!.Id,
      Name: values.vesselName,
      Imo: values.vesselImo,
      LastUpdated: rowData!.LastUpdated
    };
    await editVessel(mappedValues, {
      onSuccess: () => handleClose()
    });
  };

  return {
    initialValues: initialValues,
    isOpen: isEditModalOpen,
    close: handleClose,
    submitStatus: status,
    submit: handleSubmit
  };
}

export default useEditVessel;
