import React, { FC } from "react";
import { Button, ButtonProps, Spinner } from "react-bootstrap";
import { QueryStatus } from "react-query";

interface Props extends ButtonProps {
  status?: QueryStatus;
  onClick?: () => void;
}

const ButtonLoading: FC<Props> = ({ status, children, ...rest }) => {
  return (
    <Button disabled={status === "loading"} {...rest}>
      {status === "loading" ? (
        <Spinner animation="border" size="sm" data-testid="button-loading-spinner" />
      ) : (
        children
      )}
    </Button>
  );
};

export default ButtonLoading;
