import React, { FC } from "react";
import { Formik, Form } from "formik";
import { Row, Col, Modal, Button } from "react-bootstrap";

// COMPONENTS:
import FormikInput from "../../../components/forms/FormikInput";
import { FormattedMessage } from "react-intl";
import { QueryStatus } from "react-query";
import FormGroupField from "../../../components/forms/FormGroupField";

export type VesselFormValues = {
  vesselName: string;
  vesselImo: string;
};

interface Props {
  handleSubmit: (values: VesselFormValues) => void;
  handleClose: () => void;
  initialValues?: VesselFormValues;
  submitStatus?: QueryStatus;
}

const VesselForm: FC<Props> = ({
  handleSubmit,
  handleClose,
  initialValues = {
    vesselName: "",
    vesselImo: ""
  }
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <>
            <Form>
              <Modal.Body>
                <Row>
                  <Col xs={6} lg={4}>
                    <FormGroupField
                      label={
                        <FormattedMessage id="PORT_CALLS.PORT_CALL_FORM.VESSEL_NAME" />
                      }
                      name="vesselName"
                      component={FormikInput}
                    />
                  </Col>
                  <Col xs={6} lg={4}>
                    <FormGroupField
                      label={
                        <FormattedMessage id="PORT_CALLS.PORT_CALL_FORM.VESSEL_IMO" />
                      }
                      name="vesselImo"
                      component={FormikInput}
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  <FormattedMessage id="GLOBAL.CLOSE" />
                </Button>
                <Button variant="primary" type="submit">
                  <FormattedMessage id="GLOBAL.SAVE" />
                </Button>
              </Modal.Footer>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default VesselForm;
