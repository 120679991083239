import mapboxgl from "mapbox-gl";
import {
  SupplyPitType,
  SupplyPitWebSocketStateType,
  SupplyPitWebSocketType
} from "../../../../api/supplypits/types";
import { MutableRefObject } from "react";
import { SupplyPitsWithWebSocketData } from "../useSupplyPits";

// method passed to on click listener on supply pits layer
// opens up Popup with initial data and saves opened popup onto ref
export function onSupplyPitClick(
  e: any,
  map: MutableRefObject<mapboxgl.Map | null>,
  popup: MutableRefObject<{ id: string; popup: mapboxgl.Popup } | null>
) {
  const supplyPitId = e.features[0].properties.id;
  const state = e.features[0].properties.state;

  const socketData: SupplyPitWebSocketType = JSON.parse(
    e.features[0].properties.socketData
  );
  const supplyPit: SupplyPitType = JSON.parse(
    e.features[0].properties.supplyPit
  );
  const coordinates = e.features[0].geometry.coordinates.slice();

  // Ensure that if the map is zoomed out such that multiple
  // copies of the feature are visible, the popup appears
  // over the copy being pointed to.
  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
  }

  const content = parsePopupContent(socketData, supplyPit, state);

  const popupElement = new mapboxgl.Popup({
    className: "supply-pit-popup",
    closeButton: false,
    offset: [0, 0]
  })
    .setLngLat(coordinates)
    .setHTML(content)
    .addTo(map.current!);

  // save popup into ref, it's needed to update HTML content on popup
  // when new data from sockets comes
  popup.current = {
    id: supplyPitId,
    popup: popupElement
  };
}

// updates currently open popup
// with fresh html content based on data from socket
export function updateCurrentlyOpenedPopup(
  popup: MutableRefObject<{ id: string; popup: mapboxgl.Popup } | null>,
  socketsMap: Map<string, SupplyPitsWithWebSocketData>
) {
  if (popup.current) {
    const dataForPopup = socketsMap.get(popup.current.id);

    if (dataForPopup) {
      const parsedSocketData: SupplyPitWebSocketType = JSON.parse(
        dataForPopup.socketData
      );
      const content = parsePopupContent(
        parsedSocketData,
        dataForPopup.supplyPit,
        parsedSocketData.State
      );
      popup.current.popup.setHTML(content);
    }
  }
}

// set up html content and coordinates for popup
export function parsePopupContent(
  socketData: SupplyPitWebSocketType,
  supplyPit: SupplyPitType,
  state: SupplyPitWebSocketStateType
) {
  let stateMessage;
  switch (state) {
    case "On":
      stateMessage = "on";
      break;

    case "Off":
      stateMessage = "off";
      break;

    case "Starting":
      stateMessage = "starting";
      break;

    case "Stopping":
      stateMessage = "stopping";
      break;

    case "NoData":
      stateMessage = "No data received";
      break;

    default:
      stateMessage = "Unknown";
      break;
  }

  const content = `
    <div>
      <h3 class="font-weight-bolder">
        ${supplyPit.LocationName} ${supplyPit.Name}
      </h3>
      
      <div class="small d-flex align-items-center mb-1">
        <i class="fa fa-bolt"></i>
        <span class="ml-2">
         ${supplyPit.HighVoltage ? "High voltage" : "Low Voltage"}
        </span>
      </div>
      
      ${
        socketData.HasAlarms
          ? `<h4 class="hasAlarm">Alarm <i class="hasAlarm fas fa-exclamation-triangle"></i></h4>`
          : ""
      }
      
      ${
        socketData.HasWarnings
          ? `<h4 class="hasWarning">Warning <i class="hasWarning fas fa-exclamation-triangle"></i></h4>`
          : ""
      }
      
      <h4 class="state ${socketData.State}">${stateMessage}</h4>
      
      ${
        state === "On"
          ? `<div class="row">
                <div class="col-7 text-nowrap">Voltage:</div><div class="col-5 text-nowrap">${socketData.Measurements.Voltage} V</div>
              </div>
              
              <div class="row">
                <div class="col-7 text-nowrap">Active Power:</div><div class="col-5 text-nowrap">${socketData.Measurements.ActivePower} kW</div>
              </div>
              
              <div class="row">
                <div class="col-7 text-nowrap">Current:</div><div class="col-5 text-nowrap">${socketData.Measurements.Current} A</div>
              </div>
              
              <div class="row">
                <div class="col-7 text-nowrap">Frequency:</div><div class="col-5 text-nowrap">${socketData.Measurements.Frequency} Hz</div>
              </div>
          `
          : ""
      }
    </div>`;

  return content;
}
