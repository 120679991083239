import React, { FC } from "react";
import { Form } from "react-bootstrap";
import { FieldProps } from "formik";

interface Props extends FieldProps {
  showFeedback?: boolean;
}

const FormikInput: FC<Props> = ({
  field,
  form,
  showFeedback = true,
  ...rest
}) => {
  const isInvalid = form.touched[field.name] && !!form.errors[field.name];
  return (
    <>
      <Form.Control isInvalid={isInvalid} {...field} {...rest} />
      {showFeedback && isInvalid ? (
        <Form.Control.Feedback type="invalid" data-testid="formik-input-error">
          {form.errors[field.name]}
        </Form.Control.Feedback>
      ) : (
        <div className="input-feedback-text-placeholder" />
      )}
    </>
  );
};

export default FormikInput;
