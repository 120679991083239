import React, { FC } from "react";
import { FieldProps } from "formik";
import { Form } from "react-bootstrap";

interface Props extends FieldProps {
  showFeedback?: boolean;
  label?: string | JSX.Element;
}

// Bootstrap size and radius overrides can be found
// src/_metronic/_assets/sass/components/_variables.bootstrap.scss

const FormikCheckbox: FC<Props> = ({
  field,
  form,
  showFeedback = true,
  ...rest
}) => {
  const isInvalid = form.touched[field.name] && !!form.errors[field.name];
  return (
    <>
      <Form.Group
        className="validated mb-0" // .validated necessary for is-invalid class to have display: block
        controlId={`${field.name}CheckboxControlId`}
      >
        <Form.Check
          custom
          type="checkbox"
          checked={field.value}
          isInvalid={isInvalid}
          {...field}
          {...rest}
        />
        {showFeedback && isInvalid ? (
          <Form.Control.Feedback
            type="invalid"
            data-testid="formik-input-error"
          >
            {form.errors[field.name]}
          </Form.Control.Feedback>
        ) : (
          <div className="input-feedback-text-placeholder" />
        )}
      </Form.Group>
    </>
  );
};

export default FormikCheckbox;
