import { useEffect, useState } from "react";
import { getSupplyPits, supplyPitWebSocketUrl } from "../../../api/supplypits";
import { SupplyPitType } from "../../../api/supplypits/types";
import { useQuery } from "react-query";

export type SupplyPitsWithWebSocketData = {
  supplyPit: SupplyPitType;
  socketData: string;
  socket: WebSocket;
};

function useSupplyPits() {
  const [socketsMap, setSocketsMap] = useState<
    Map<string, SupplyPitsWithWebSocketData>
  >(new Map());

  const { data: supplyPits } = useQuery(
    "supplyPits",
    async () => {
      const res = await getSupplyPits();
      return res.data;
    },
    {
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    if (supplyPits?.length) {
      supplyPits.forEach(supplyPit => {
        // add supply pits to map with state of NoData
        // before they connect to sockets
        setSocketsMap(
          prev =>
            new Map(
              prev.set(supplyPit.Id, {
                supplyPit,
                socketData: JSON.stringify({
                  State: "NoData"
                }),
                socket
              })
            )
        );

        // setup socket connection
        const socket = new WebSocket(
          supplyPitWebSocketUrl(
            supplyPit.ShorePowerUnit.VendorAssignedId,
            supplyPit.VendorAssignedId
          )
        );

        // set data to socketsMap when socket responds
        socket.onmessage = evt => {
          setSocketsMap(
            prev =>
              new Map(
                prev.set(supplyPit.Id, {
                  supplyPit,
                  socketData: evt.data,
                  socket
                })
              )
          );
        };
      });
    }

    return () => socketsMap.forEach(({ socket }) => socket?.close());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplyPits]);

  return { socketsMap };
}

export default useSupplyPits;
