import React, { FC } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useIntl } from "react-intl";

// LOGIC:
import useSPUnits from "./useSPUnits";
import useSPUnitsStore from "../SPUnitsStore";

// COMPONENTS:
import ActionsFormatter from "./components/Formatters/ActionsFormatter";
import {
  headerSortingClasses,
  SortCaret
} from "../../../utils/table/sortColumn";
import shallow from "zustand/shallow";
import { SPUnitType } from "../../../api/spUnits/types";

const SPUnitsTable: FC = () => {
  const { setEditModalOpen, setDeleteModalOpen } = useSPUnitsStore(
    s => ({
      setEditModalOpen: s.setEditModalOpen,
      setDeleteModalOpen: s.setDeleteModalOpen
    }),
    shallow
  );

  const openEditModal = (rowData: SPUnitType) =>
    setEditModalOpen(true, rowData);
  const openDeleteModal = (rowData: SPUnitType) =>
    setDeleteModalOpen(true, rowData);

  const intl = useIntl();

  const { data } = useSPUnits();

  const columns = [
    {
      dataField: "Name",
      text: intl.formatMessage({ id: "SPUNITS.TABLE.NAME" }),
      sort: true,
      sortCaret: SortCaret,
      headerSortingClasses
    },
    {
      dataField: "Location.Y",
      text: intl.formatMessage({ id: "SUPPLYPITS.SUPPLYPIT_FORM.LATITUDE" }),
      sort: true,
      sortCaret: SortCaret,
      headerSortingClasses
    },
    {
      dataField: "Location.X",
      text: intl.formatMessage({ id: "SUPPLYPITS.SUPPLYPIT_FORM.LONGITUDE" }),
      sort: true,
      sortCaret: SortCaret,
      headerSortingClasses
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      formatter: ActionsFormatter,
      formatExtraData: {
        openEditModal: openEditModal,
        openDeleteModal: openDeleteModal
      },
      align: "right",
      headerAlign: "right"
    }
  ];

  return (
    <>
      <BootstrapTable
        bordered={false}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        keyField="Id"
        data={data || []}
        columns={columns}
      />
    </>
  );
};

export default SPUnitsTable;
