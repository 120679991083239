import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  LayoutSplashScreen,
  MaterialThemeProvider,
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider
} from "./_metronic/layout";
import { I18nProvider, MetronicI18nProvider } from "./_metronic/i18n";
import SnackbarProvider from "./components/providers/SnackbarProvider";
import Auth0Provider from "./components/providers/Auth0Provider";
import ReactQueryProvider from "./components/providers/ReactQueryProvider";

// APP:
import App from "./App";

// STYLES:
import "./index.scss";
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import "react-datepicker/dist/react-datepicker.css";

export const history = createBrowserHistory();

ReactDOM.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          <Suspense fallback={<LayoutSplashScreen />}>
            <Router history={history}>
              <MaterialThemeProvider>
                <I18nProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Auth0Provider>
                      <SnackbarProvider>
                        <ReactQueryProvider>
                          <App />
                        </ReactQueryProvider>
                      </SnackbarProvider>
                    </Auth0Provider>
                  </MuiPickersUtilsProvider>
                </I18nProvider>
              </MaterialThemeProvider>
            </Router>
          </Suspense>
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
  document.getElementById("root")
);
