import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import {
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";

// UI LOGIC:
import useVesselsStore from "../../vesselsStore";
import shallow from "zustand/shallow";

const VesselsHeader: FC = () => {
  const { setNewModalOpen } = useVesselsStore(
    s => ({ setNewModalOpen: s.setNewModalOpen }),
    shallow
  );

  const openModal = () => setNewModalOpen(true);

  return (
    // @ts-ignore
    <CardHeader
      title={
        <h4 className="mb-0">
          <FormattedMessage id="VESSELS.TABLE.TITLE" />
        </h4>
      }
    >
      {/* @ts-ignore */}
      <CardHeaderToolbar>
        <button className="btn btn-primary my-2" onClick={openModal}>
          <FormattedMessage id="VESSELS.VESSEL_FORM.NEW_FORM_TITLE" />
        </button>
      </CardHeaderToolbar>
    </CardHeader>
  );
};

export default VesselsHeader;
