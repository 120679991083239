import React, { FC, useCallback, useMemo } from "react";
import { ReactQueryConfigProvider } from "react-query";
import { ReactQueryConfig } from "react-query/types/core/types";
import { SnackbarKey, useSnackbar } from "notistack";
import { FormattedMessage } from "react-intl";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const ReactQueryProvider: FC = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const action = useCallback(
    (key: SnackbarKey) => (
      <IconButton
        className="text-white"
        aria-label="delete"
        onClick={() => closeSnackbar(key)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    ),
    [closeSnackbar]
  );

  const handleError = useCallback(
    (error: any) => {
      enqueueSnackbar(
        error?.data?.Message || <FormattedMessage id="GLOBAL.ERROR_MESSAGE" />,
        {
          variant: "error",
          action
        }
      );
    },
    [enqueueSnackbar, action]
  );

  const overrides = useMemo<ReactQueryConfig<any, any>>(
    () => ({
      queries: {
        onError: handleError
      },
      mutations: {
        onError: handleError
      }
    }),
    [handleError]
  );

  return (
    <ReactQueryConfigProvider config={overrides}>
      {children}
    </ReactQueryConfigProvider>
  );
};

export default ReactQueryProvider;
