import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import "../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { FormattedMessage } from "react-intl";
// import { Button } from "react-bootstrap";
// import { useAuth0 } from "@auth0/auth0-react";

export function AuthPageWrapper({ children }) {
  // const { loginWithRedirect } = useAuth0();

  // const handleSignup = async () => {
  //   await loginWithRedirect({ screen_hint: "signup" });
  // };

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/400.jpg")})`
            }}
          >
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              <Link to="/" className="flex-column-auto mt-5">
                <img
                  alt="Logo"
                  className="max-h-70px"
                  src={toAbsoluteUrl("/media/logos/plug_logo.png")}
                />
              </Link>
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-white">
                  <FormattedMessage id="AUTH.TITLE" />
                </h3>
                {/*<p className="font-weight-lighter text-white opacity-80">*/}
                {/*  <FormattedMessage id="AUTH.SUBTITLE" />*/}
                {/*</p>*/}
              </div>

              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy; {new Date().getFullYear()}{" "}
                  <FormattedMessage id="BRAND.NAME" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              {/*<span className="font-weight-bold text-dark-50">*/}
              {/*  <FormattedMessage id="AUTH.GENERAL.NO_ACCOUNT_YET" />*/}
              {/*</span>*/}
              {/*<Button variant="link" className="px-2" onClick={handleSignup}>*/}
              {/*  <FormattedMessage id="AUTH.GENERAL.SIGNUP_BUTTON" />*/}
              {/*</Button>*/}
            </div>

            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              {children}
            </div>

            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; {new Date().getFullYear()}{" "}
                <FormattedMessage id="BRAND.NAME" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
