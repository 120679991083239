import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

// COMPONENTS:
import ButtonLoading from "../../../../components/forms/ButtonLoading";

// LOGIC:
import useDeleteSupplyPit from "./useDeleteSupplyPit";

const DeleteSupplyPitModal: FC = () => {
  const {
    isOpen,
    close,
    submit,
    submitStatus,
    supplyPitName
  } = useDeleteSupplyPit();

  return (
    <Modal
      show={isOpen}
      onHide={close}
      size="lg"
      backdrop="static"
      keyboard={false}
      autoFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="SUPPLYPITS.DELETE.TITLE" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-5 font-size-lg">
          <FormattedMessage
            id="SUPPLYPITS.DELETE.WARNING"
            values={{ supplyPitName: <b>{supplyPitName}</b> }}
          />
        </p>
        <div className="font-size-lg">
          <FormattedMessage id="GLOBAL.DELETE.WARNING" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          <FormattedMessage id="GLOBAL.CLOSE" />
        </Button>
        <ButtonLoading status={submitStatus} variant="danger" onClick={submit}>
          <FormattedMessage id="GLOBAL.DELETE" />
        </ButtonLoading>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteSupplyPitModal;
