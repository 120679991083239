import { useCallback, useEffect, useRef, useState } from "react";
import mapboxgl, { GeoJSONSource } from "mapbox-gl";
import useSupplyPits from "./useSupplyPits";
import { loadInitialSupplyPitsOntoMap, updateSupplyPitsOnMap } from "./utils/map";
import { onSupplyPitClick, updateCurrentlyOpenedPopup } from "./utils/popup";

const mapEnv = {
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  style: process.env.REACT_APP_MAPBOX_STYLE
};

const mapInitialConfig = {
  style: mapEnv.style,
  lat: 60.3995686,
  lng: 5.3117833,
  zoom: 16
};

function useMapbox(container: string) {
  const [loaded, setLoaded] = useState(false);
  const map = useRef<mapboxgl.Map | null>(null);
  const popup = useRef<{ id: string; popup: mapboxgl.Popup } | null>(null);

  const { socketsMap } = useSupplyPits();

  const initializeMap = useCallback(() => {
    // initialize map
    map.current = new mapboxgl.Map({
      container: container,
      style: mapInitialConfig.style,
      zoom: mapInitialConfig.zoom,
      center: [mapInitialConfig.lng, mapInitialConfig.lat],
      accessToken: mapEnv.accessToken
    });

    map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");
    map.current.addControl(new mapboxgl.FullscreenControl(), "bottom-right");

    map.current.on("load", () => {
      map.current?.setPitch(60);
      setLoaded(true);
    });

    // change the cursor to a pointer when the mouse is over the places layer.
    map.current.on("mouseenter", "supply-pits", function() {
      map.current!.getCanvas().style.cursor = "pointer";
    });

    // change it back to a pointer when it leaves.
    map.current.on("mouseleave", "supply-pits", function() {
      map.current!.getCanvas().style.cursor = "";
    });

    // sets up on click listener and displays popup on source layer click
    map.current?.on("click", "supply-pits", (e) =>
      onSupplyPitClick(e, map, popup)
    );
  }, [container]);

  useEffect(() => {
    // wait for map to load resources and sockets to send first data
    if (!!socketsMap.size && loaded) {
      // get current source with id of supply-pits
      const currentSource = map.current?.getSource(
        "supply-pits"
      ) as GeoJSONSource;

      // if source exist, update data => trigger rerender on map
      if (Boolean(currentSource)) {
        updateSupplyPitsOnMap(currentSource, socketsMap);
        updateCurrentlyOpenedPopup(popup, socketsMap);
      } else {
        loadInitialSupplyPitsOntoMap(map, socketsMap);
      }
    }
  }, [socketsMap, loaded]);

  return { map: map.current, initializeMap };
}

export default useMapbox;
