/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import {FormattedMessage} from "react-intl";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text"><FormattedMessage id="HEADER.DASHBOARD" /></span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          {/*<li*/}
          {/*    className={`menu-item ${getMenuItemActive("/port-calls", false)}`}*/}
          {/*    aria-haspopup="true"*/}
          {/*>*/}
          {/*  <NavLink className="menu-link" to="/port-calls">*/}
          {/*  <span className="menu-icon">*/}
          {/*    <i className="fas fa-charging-station color-inherit"></i>*/}
          {/*  </span>*/}
          {/*    <span className="menu-text"><FormattedMessage id="HEADER.PORTCALLS" /></span>*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive("/supply-pits", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/supply-pits">
            <span className="menu-icon">
              <i className="fab fa-codepen color-inherit"></i>
            </span>
              <span className="menu-text"><FormattedMessage id="HEADER.SUPPLYPITS" /></span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive("/shore-power-units", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/shore-power-units">
            <span className="menu-icon">
              <i className="fas fa-gopuram color-inherit"></i>
            </span>
              <span className="menu-text"><FormattedMessage id="HEADER.SPUNITS" /></span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive("/vessels", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/vessels">
            <span className="menu-icon">
              {/*<SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>*/}
              <i className="fas fa-ship color-inherit"></i>
            </span>
              <span className="menu-text"><FormattedMessage id="HEADER.VESSELS" /></span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          {/*<li*/}
          {/*  className={`menu-item ${getMenuItemActive("/customers", false)}`}*/}
          {/*  aria-haspopup="true"*/}
          {/*>*/}
          {/*  <NavLink className="menu-link" to="/customers">*/}
          {/*  <span className="svg-icon menu-icon">*/}
          {/*    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}/>*/}
          {/*  </span>*/}
          {/*    <span className="menu-text"><FormattedMessage id="HEADER.CUSTOMERS" /></span>*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
          {/*end::1 Level*/}

          {/* Invoices */}
          {/* begin::section */}
          {/*<li className="menu-section ">*/}
          {/*  <h4 className="menu-text"><FormattedMessage id="HEADER.INVOICES" /></h4>*/}
          {/*  <i className="menu-icon flaticon-more-v2"></i>*/}
          {/*</li>*/}
          {/* end:: section */}

          {/*begin::1 Level*/}
          {/*<li*/}
          {/*  className={`menu-item ${getMenuItemActive("/invoices", false)}`}*/}
          {/*  aria-haspopup="true"*/}
          {/*>*/}
          {/*  <NavLink className="menu-link" to="/invoices">*/}
          {/*  <span className="menu-icon">*/}
          {/*    <i className="fas fa-file-alt color-inherit"></i>*/}
          {/*  </span>*/}
          {/*    <span className="menu-text"><FormattedMessage id="INVOICES.TAB.DRAFT.TITLE" /></span>*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          {/*<li*/}
          {/*  className={`menu-item ${getMenuItemActive("/invoices", false)}`}*/}
          {/*  aria-haspopup="true"*/}
          {/*>*/}
          {/*  <NavLink className="menu-link" to="/invoices">*/}
          {/*  <span className="menu-icon">*/}
          {/*    <i className="fas fa-file-invoice-dollar color-inherit"></i>*/}
          {/*  </span>*/}
          {/*    <span className="menu-text"><FormattedMessage id="INVOICES.TAB.INVOICED.TITLE" /></span>*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
          {/*end::1 Level*/}
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
