import { queryCache, useMutation } from "react-query";

// API:
import { addVessel as addVesselAPI } from "../../../../api/vessels";

// LOGIC:
import useVesselsStore from "../../vesselsStore";
import shallow from "zustand/shallow";
import { AddVesselType } from "../../../../api/vessels/types";
import { VesselFormValues } from "../VesselForm";

function useNewVessel() {
  const { isNewModalOpen, setNewModalOpen } = useVesselsStore(
    s => ({
      isNewModalOpen: s.isNewModalOpen,
      setNewModalOpen: s.setNewModalOpen
    }),
    shallow
  );

  const handleClose = () => setNewModalOpen(false);

  const [addVessel, { status }] = useMutation(async (data: AddVesselType) => {
    const res = await addVesselAPI(data);
    await queryCache.invalidateQueries("vessels");
    return res.data;
  });

  const handleSubmit = async (values: VesselFormValues) => {
    const mappedValues = {
      Name: values.vesselName,
      Imo: values.vesselImo
    };
    await addVessel(mappedValues, {
      onSuccess: () => handleClose()
    });
  };

  return {
    isOpen: isNewModalOpen,
    close: handleClose,
    submitStatus: status,
    submit: handleSubmit
  };
}

export default useNewVessel;
