import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../_metronic/layout";
import { isAuthenticated } from "../../utils/auth";

class Logout extends Component {
  componentDidMount() {}

  render() {
    return isAuthenticated() ? (
      <LayoutSplashScreen />
    ) : (
      <Redirect to="/login" />
    );
  }
}

export default Logout;
