import { useQuery } from "react-query";
import { getSPUnits } from "../../../api/spUnits";
import shallow from "zustand/shallow";

// LOGIC:
import useLocalSearch from "../../../utils/hooks/useLocalSearch";
import useSPUnitsTableStore from "./useSPUnitsTableStore";

function useSPUnits() {
  const { search } = useSPUnitsTableStore(
    s => ({ search: s.search }),
    shallow
  );

  const { data: spUnits } = useQuery("spUnits", async () => {
    const res = await getSPUnits();
    return res.data;
  });

  const { data } = useLocalSearch(
    search,
    ["Name", "Location.Y", "Location.X"],
    spUnits
  );

  return { data };
}

export default useSPUnits;
