import request, { APICall } from "../request";
import { EditSupplyPitType, NewSupplyPitType, SupplyPitType } from "./types";

export const getSupplyPits = (): APICall<SupplyPitType[]> =>
  request({
    url: "/supplypits",
    method: "GET"
  });

export const addSupplyPit = (
  data: NewSupplyPitType
): APICall<NewSupplyPitType> =>
  request({
    data,
    url: "/supplypits",
    method: "POST"
  });

export const editSupplyPit = (
  data: EditSupplyPitType
): APICall<EditSupplyPitType> =>
  request({
    data,
    url: "/supplypits",
    method: "PATCH"
  });

export const deleteSupplyPit = (portCallId: string): APICall<null> =>
  request({
    url: `/supplypits/${portCallId}`,
    method: "DELETE"
  });

export const supplyPitWebSocketUrl = (shorePowerUnitID: string, vendorAssignedID: string) =>
  `wss://api.plug.ilderserv.no/stream/v0/vendor/powercon/ops/${shorePowerUnitID}/supply_pit/${vendorAssignedID}`;
