import request, { APICall } from "../request";
import { AddVesselType, EditVesselType, VesselType } from "./types";

export const getVessels = (): APICall<VesselType[]> =>
  request({
    url: "/vessels",
    method: "GET"
  });

export const addVessel = (data: AddVesselType): APICall<AddVesselType> =>
  request({
    data,
    url: "/vessels",
    method: "POST"
  });

export const editVessel = (data: EditVesselType): APICall<EditVesselType> =>
  request({
    data,
    url: "/vessels",
    method: "PATCH"
  });

export const deleteVessel = (customerId: string): APICall<null> =>
  request({
    url: `/vessels/${customerId}`,
    method: "DELETE"
  });
