import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import {
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls/index";

import useCustomersStore from "../../supplyPitsStore";

const SupplyPitsHeader: FC = () => {
  const { setNewModalOpen } = useCustomersStore(s => ({
    setNewModalOpen: s.setNewModalOpen
  }));

  const openModal = () => setNewModalOpen(true);

  return (
    // @ts-ignore
    <CardHeader
      title={
        <h4 className="mb-0">
          <FormattedMessage id="SUPPLYPITS.TABLE.TITLE" />
        </h4>
      }
    >
      {/* @ts-ignore */}
      <CardHeaderToolbar>
        <button className="btn btn-primary my-2" onClick={openModal}>
          <FormattedMessage id="SUPPLYPITS.SUPPLYPIT_FORM.NEW_FORM_TITLE" />
        </button>
      </CardHeaderToolbar>
    </CardHeader>
  );
};

export default SupplyPitsHeader;
