import axios, { AxiosError, AxiosResponse } from "axios";
import { deleteToken } from "../utils/auth";
import { history } from "../index";

const defaultValues = { baseURL: process.env.REACT_APP_API_URL };

export const axiosClient = axios.create(defaultValues);

const request = ({ ignoreUnauthorizedLogout = false, ...options }) => {
  // Authorization header with Bearer token is set up in PrivateRoute component

  const onSuccess = (response: AxiosResponse) => response;
  const onError = (error: AxiosError) => {
    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      !ignoreUnauthorizedLogout
    ) {
      deleteToken();
      const lastLocation = window.btoa(window.location.href);
      if (!window.location.pathname.startsWith("/membership")) {
        history.push(`/login?target=${lastLocation}`);
      }
    }

    /* tslint:disable:no-console */
    // DISPLAY API ERROR MESSAGES ON DEV ENVIRONMENT
    if (process.env.NODE_ENV === "development") {
      if (error.response) {
        // Request was made but server responded with something
        // other than 2xx
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
        console.error("Headers:", error.response.headers);
      } else {
        // Something else happened while setting up the request
        // triggered the error
        console.error("Error Message:", error.message);
      }
    }
    /* tslint:enable:no-console */

    return Promise.reject(error.response || error.message);
  };

  return axiosClient(options)
    .then(onSuccess)
    .catch(onError);
};

export default request;

export type APICall<T> = Promise<AxiosResponse<T>>;
