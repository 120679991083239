import React, { FC } from "react";
import { Form } from "react-bootstrap";
import { Field, FieldAttributes } from "formik";

interface Props extends FieldAttributes<any> {
  label?: string | JSX.Element;
  checkBoxLabel?: string | JSX.Element;
}

/**
 * Wrapper for bootstrap's Form.Group including Form.Label and Formik's Field
 */
const FormGroupField: FC<Props> = ({ component, children, label, name, ...rest }) => {
  const idForLabel = rest.id || `${name}IdForLabel`;
  return (
    <Form.Group className="mb-2">
      <Form.Label htmlFor={idForLabel}>{label}</Form.Label>
      <Field id={idForLabel} name={name} component={component} {...rest}>
        {children}
      </Field>
    </Form.Group>
  );
};

export default FormGroupField;
