import React, { FC } from "react";
import {
  Auth0Provider as Auth0ActualProvider,
  Auth0ProviderOptions
} from "@auth0/auth0-react";

const auth0options: Auth0ProviderOptions = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN || "variable_not_specified",
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || "variable_not_specified",
  redirectUri: `${window.location.origin}/dashboard`,
  useRefreshTokens: true,
  cacheLocation: "localstorage"
};

const Auth0Provider: FC = ({ children }) => {
  return (
    <Auth0ActualProvider {...auth0options}>{children}</Auth0ActualProvider>
  );
};

export default Auth0Provider;
