import React from "react";
import { Card } from "../../_metronic/_partials/controls";

// COMPONENTS:
import VesselsHeader from "./VesselsTable/components/VesselsHeader";
import VesselsTableFilters from "./VesselsTable/components/VesselsTableFilters";
import VesselsTable from "./VesselsTable";
// import VesselsPagination from "./VesselsTable/components/VesselsPagination";
import NewVesselModal from "./components/NewVessel/NewVesselModal";
import EditVesselModal from "./components/EditVessel/EditVesselModal";
import DeleteVesselModal from "./components/DeleteVessel/DeleteVesselModal";

const VesselsPage = () => {
  return (
    <>
      {/* @ts-ignore */}
      <Card>
        <VesselsHeader />
        <VesselsTableFilters />
        <VesselsTable />
        {/*<VesselsPagination />*/}
      </Card>
      <NewVesselModal />
      <EditVesselModal />
      <DeleteVesselModal />
    </>
  );
};

export default VesselsPage;
