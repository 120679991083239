import React, { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { AuthPageWrapper } from "../../modules/auth/AuthPageWrapper";
import { useAuth0 } from "@auth0/auth0-react";
import { LayoutSplashScreen } from "../../_metronic/layout";

const AuthRoute: FC<RouteProps> = props => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <LayoutSplashScreen />;
  }

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <AuthPageWrapper>
      <Route {...props} />
    </AuthPageWrapper>
  );
};

export default AuthRoute;
