import { useMemo } from "react";
import { queryCache, useMutation } from "react-query";

// API:
import { editSupplyPit as editSupplyPitAPI } from "../../../../api/supplypits";

// LOGIC:
import useSupplyPitsStore from "../../supplyPitsStore";
import shallow from "zustand/shallow";
import { SupplyPitFormValues } from "../SupplyPitForm";
import { isString } from "formik";
import { EditSupplyPitType } from "../../../../api/supplypits/types";

function useEditSupplyPit() {
  const { isEditModalOpen, rowData, setEditModalOpen } = useSupplyPitsStore(
    s => ({
      isEditModalOpen: s.isEditModalOpen,
      rowData: s.rowData,
      setEditModalOpen: s.setEditModalOpen
    }),
    shallow
  );

  const handleClose = () => setEditModalOpen(false, null);

  const initialValues = useMemo<SupplyPitFormValues>(
    () => ({
      shorePowerUnitId: rowData?.ShorePowerUnitId || "",
      name: rowData?.Name || "",
      locationName: rowData?.LocationName || "",
      lat: rowData?.Location?.Y || 0,
      lng: rowData?.Location?.X || 0,
      highVoltage: Boolean(rowData?.HighVoltage),
      vendorAssignedId: rowData?.VendorAssignedId || ""
    }),
    [rowData]
  );

  const [editSupplyPit, { status }] = useMutation(
    async (data: EditSupplyPitType) => {
      const res = await editSupplyPitAPI(data);
      await queryCache.invalidateQueries("supplypits");
      return res.data;
    }
  );

  const handleSubmit = async (values: SupplyPitFormValues) => {
    const mappedValues = {
      Id: rowData!.Id,
      ShorePowerUnitId: values.shorePowerUnitId,
      Name: values.name,
      LocationName: values.locationName,
      Location: {
        Y: values.lat === 0 || isString(values.lat) ? 0 : values.lat,
        X: values.lng === 0 || isString(values.lng) ? 0 : values.lng
      },
      HighVoltage: values.highVoltage,
      VendorAssignedId: values.vendorAssignedId,
      LastUpdated: rowData!.LastUpdated
    };
    await editSupplyPit(mappedValues, {
      onSuccess: () => handleClose()
    });
  };

  return {
    initialValues: initialValues,
    isOpen: isEditModalOpen,
    close: handleClose,
    submitStatus: status,
    submit: handleSubmit
  };
}

export default useEditSupplyPit;
