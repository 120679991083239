import React, { FC } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import useBreadcrumbStore from "./stores/BreadcrumbStore";
import shallow from "zustand/shallow";
const CustomBreadcrumbs: FC = () => {
  const { breadcrumbs } = useBreadcrumbStore(
    s => ({ breadcrumbs: s.breadcrumbs }),
    shallow
  );

  return (
    <div className="d-flex align-items-center pt-5 pt-lg-0">
      <h5 className="text-dark font-weight-bold my-1 mr-5">Bergen Havn</h5>
      <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={`bc${index}`} className="breadcrumb-item">
            <Link className="text-muted" to={{ pathname: breadcrumb.pathname }}>
              {breadcrumb.translationId ? (
                <FormattedMessage id={breadcrumb.translationId} />
              ) : (
                breadcrumb.title
              )}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomBreadcrumbs;
