import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";

// ROUTES:
import PrivateRoute from "./components/routes/PrivateRoute";
import AuthRoute from "./components/routes/AuthRoute";

// AUTH:
import Login from "./modules/auth/Login";
import Logout from "./modules/auth/Logout";

// PRIVATE:
import DashboardPage from "./modules/dashboard";
import SPUnitsPage from "./modules/SPUnits";
import SupplyPitsPage from "./modules/supplypits";
// import PortCallsPage from "./modules/portcalls";
import VesselsPage from "./modules/vessels";
// import CustomersPage from "./modules/customers";
// import InvoicesPage from "./modules/invoices";
// import NewInvoicePage from "./modules/invoices/new";
// import EditInvoicePage from "./modules/invoices/edit";

export default function App() {
  return (
    <Switch>
      <AuthRoute exact component={Login} path="/login" />
      <Route path="/logout" component={Logout} />
      <Redirect exact from="/" to="/dashboard" />

      <PrivateRoute
        exact
        path="/dashboard"
        component={DashboardPage}
        breadcrumbs={[
          { translationId: "HEADER.DASHBOARD", pathname: "/dashboard" }
        ]}
      />
      <PrivateRoute
        exact
        path="/supply-pits"
        component={SupplyPitsPage}
        breadcrumbs={[
          { translationId: "HEADER.SUPPLYPITS", pathname: "/supply-pits" }
        ]}
      />
      <PrivateRoute
        exact
        path="/shore-power-units"
        component={SPUnitsPage}
        breadcrumbs={[
          { translationId: "HEADER.SPUNITS", pathname: "/shore-power-units" }
        ]}
      />
      {/*<PrivateRoute*/}
      {/*  exact*/}
      {/*  path="/port-calls"*/}
      {/*  component={PortCallsPage}*/}
      {/*  breadcrumbs={[*/}
      {/*    { translationId: "HEADER.PORTCALLS", pathname: "/port-calls" }*/}
      {/*  ]}*/}
      {/*/>*/}
      <PrivateRoute
        exact
        path="/vessels"
        component={VesselsPage}
        breadcrumbs={[
          { translationId: "HEADER.VESSELS", pathname: "/vessels" }
        ]}
      />
      {/*<PrivateRoute*/}
      {/*  exact*/}
      {/*  path="/customers"*/}
      {/*  component={CustomersPage}*/}
      {/*  breadcrumbs={[*/}
      {/*    { translationId: "HEADER.CUSTOMERS", pathname: "/customers" }*/}
      {/*  ]}*/}
      {/*/>*/}
      {/*<PrivateRoute*/}
      {/*  exact*/}
      {/*  path="/invoices"*/}
      {/*  component={InvoicesPage}*/}
      {/*  breadcrumbs={[*/}
      {/*    { translationId: "HEADER.INVOICES", pathname: "/invoices" }*/}
      {/*  ]}*/}
      {/*/>*/}
      {/*<PrivateRoute*/}
      {/*  exact*/}
      {/*  path="/invoices/new"*/}
      {/*  component={NewInvoicePage}*/}
      {/*  breadcrumbs={[*/}
      {/*    { translationId: "HEADER.INVOICES", pathname: "/invoices" },*/}
      {/*    {*/}
      {/*      translationId: "BREADCRUMBS.INVOICE.NEW",*/}
      {/*      pathname: "/invoices/new"*/}
      {/*    }*/}
      {/*  ]}*/}
      {/*/>*/}
      {/*<PrivateRoute*/}
      {/*  exact*/}
      {/*  path="/invoices/:invoiceId/edit"*/}
      {/*  component={EditInvoicePage}*/}
      {/*  breadcrumbs={[*/}
      {/*    { translationId: "HEADER.INVOICES", pathname: "/invoices" },*/}
      {/*    {*/}
      {/*      translationId: "BREADCRUMBS.INVOICE.EDIT",*/}
      {/*      pathname: "/invoices/:invoiceId/edit"*/}
      {/*    }*/}
      {/*  ]}*/}
      {/*/>*/}
    </Switch>
  );
}
