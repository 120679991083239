import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useIntl } from "react-intl";
import shallow from "zustand/shallow";

// LOGIC:
import useVessels from "./useVessels";

// COMPONENTS:
import ActionsFormatter from "./components/Formatters/ActionsFormatter";
import {
  headerSortingClasses,
  SortCaret
} from "../../../utils/table/sortColumn";

// STORE:
import useVesselsStore from "../vesselsStore";

// TYPES:
import {VesselType} from "../../../api/vessels/types";

const VesselsTable = () => {
  const { setEditModalOpen, setDeleteModalOpen } = useVesselsStore(
    s => ({
      setEditModalOpen: s.setEditModalOpen,
      setDeleteModalOpen: s.setDeleteModalOpen
    }),
    shallow
  );

  const openEditModal = (rowData: VesselType) => setEditModalOpen(true, rowData);
  const openDeleteModal = (rowData: VesselType) => setDeleteModalOpen(true, rowData);

  const intl = useIntl();

  const { data } = useVessels();

  const columns = [
    {
      dataField: "Name",
      text: intl.formatMessage({ id: "VESSELS.TABLE.VESSEL" }),
      sort: true,
      sortCaret: SortCaret,
      headerSortingClasses
    },
    {
      dataField: "Imo",
      text: intl.formatMessage({ id: "VESSELS.TABLE.IMO" }),
      sort: true,
      sortCaret: SortCaret,
      headerSortingClasses
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      formatter: ActionsFormatter,
      formatExtraData: {
        openEditModal,
        openDeleteModal
      },
      align: "right",
      headerAlign: "right"
    }
  ];

  return (
    <>
      <BootstrapTable
        bordered={false}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        keyField="Id"
        data={data || []}
        columns={columns}
      />
    </>
  );
};

export default VesselsTable;
